<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增厅房"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="厅房名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入厅房名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="类型">
          <a-select
            v-decorator="['hall_type',{
              rules: [
                { required: true, message: '请输入厅房类型' },
              ]
            }]"
            placeholder="请选择类型"
            allow-clear
          >
            <a-select-option value="a">
              A类
            </a-select-option>
            <a-select-option value="b">
              B类
            </a-select-option>
            <a-select-option value="c">
              C类
            </a-select-option>
            <a-select-option value="d">
              D类
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="价格">
          <a-input-number
            style="width: 80%;"
            :min="0.00"
            :max="10000000"
            :step="0.01"
            :precision="2"
            v-decorator="['hall_price', {
              rules: [
                { required: true, message: '请输入价格' },
              ]
            }]"
          /><span style="float: right;">元/小时</span>
        </a-form-item>
        <a-form-item label="图片">
          <span
            class="tip-text"
          >支持png/jpg格式照片，10M以内, 建议尺寸宽高比180:180px，最多10张
          </span>
          <upload-image
            :is-add-watermark="isAddWatermark"
            accept=".jpg,.jpeg,.png"
            :max-count="10"
            :multiple="true"
            @change="handleUploadImage"
            v-decorator="['attachments',{
              rules:[
                {validator: checkAttachments}
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="介绍">
          <a-input
            v-decorator="['remark', {
              normalize: this.$lodash.trim,
              rules: [
                { message: '请输入厅房介绍' },
                { max: 200, message: '最多200个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { createHall } from '@/api/hall'
import UploadImage from '@/components/Upload/Image'

export default {
  name: 'NewHall',
  components: {
    UploadImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    funeralHomeId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'hall_new' }),
      submitting: false,
      isAddWatermark: false,
      attachments: [] // 图片
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleUploadImage(fileList) {
      this.attachments = fileList
    },
    // 检查照片
    checkAttachments(rule, value, callback) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }

      callback()
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.$lodash.find(this.attachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            attachments: this.$lodash.map(this.attachments, 'response')
          }
          createHall(this.funeralHomeId, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
