var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"新增厅房"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"厅房名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入厅房名称' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入厅房名称' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['hall_type',{
            rules: [
              { required: true, message: '请输入厅房类型' } ]
          }]),expression:"['hall_type',{\n            rules: [\n              { required: true, message: '请输入厅房类型' },\n            ]\n          }]"}],attrs:{"placeholder":"请选择类型","allow-clear":""}},[_c('a-select-option',{attrs:{"value":"a"}},[_vm._v(" A类 ")]),_c('a-select-option',{attrs:{"value":"b"}},[_vm._v(" B类 ")]),_c('a-select-option',{attrs:{"value":"c"}},[_vm._v(" C类 ")]),_c('a-select-option',{attrs:{"value":"d"}},[_vm._v(" D类 ")])],1)],1),_c('a-form-item',{attrs:{"label":"价格"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['hall_price', {
            rules: [
              { required: true, message: '请输入价格' } ]
          }]),expression:"['hall_price', {\n            rules: [\n              { required: true, message: '请输入价格' },\n            ]\n          }]"}],staticStyle:{"width":"80%"},attrs:{"min":0.00,"max":10000000,"step":0.01,"precision":2}}),_c('span',{staticStyle:{"float":"right"}},[_vm._v("元/小时")])],1),_c('a-form-item',{attrs:{"label":"图片"}},[_c('span',{staticClass:"tip-text"},[_vm._v("支持png/jpg格式照片，10M以内, 建议尺寸宽高比180:180px，最多10张 ")]),_c('upload-image',{directives:[{name:"decorator",rawName:"v-decorator",value:(['attachments',{
            rules:[
              {validator: _vm.checkAttachments}
            ]
          }]),expression:"['attachments',{\n            rules:[\n              {validator: checkAttachments}\n            ]\n          }]"}],attrs:{"is-add-watermark":_vm.isAddWatermark,"accept":".jpg,.jpeg,.png","max-count":10,"multiple":true},on:{"change":_vm.handleUploadImage}})],1),_c('a-form-item',{attrs:{"label":"介绍"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
            normalize: this.$lodash.trim,
            rules: [
              { message: '请输入厅房介绍' },
              { max: 200, message: '最多200个字符' } ]
          }]),expression:"['remark', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { message: '请输入厅房介绍' },\n              { max: 200, message: '最多200个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }